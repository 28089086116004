import styled from 'styled-components'

export const AccordionBox = styled.div`
  border-top: 1px solid black;
  padding: 0.5rem 0;
`

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const AccordionHeaderText = styled.h2`
  margin: 0;
`

export const AccordionHeaderArrow = styled.div`
  margin-left: 1rem;
  padding: 0.5rem;
`

interface AccordionContentProps {
  readonly isOpen: boolean
}

export const AccordionContent = styled.div<AccordionContentProps>`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  padding: ${({ isOpen }) => (isOpen ? '1rem 3.4rem' : '0 3.4rem')};
  transition: all 0.3s ease-in-out, padding 0.2s;
  overflow-y: hidden;
`

import React from 'react'

import { SectionOuterBox, SectionInnerBox } from '../Section'
import { MapsIFrame } from '../MapsIFrame'

import { AdressContainer, AdressText } from './AdressStyles'
import { ContactForm } from '../ContactForm'

interface Props {
  readonly adress: {
    title: string
    adress: string
    text: string
  }
}

export const Adress = ({ adress }: Props) => (
  <SectionOuterBox prevImage nextImage>
    <SectionInnerBox id="anfahrt">
      <h2>{adress.title}</h2>
      <AdressContainer>
        <div style={{ flex: 1 }}>
          <MapsIFrame link={adress.adress} title={adress.title} aspectRaito={{ width: 16, height: 9 }} />
        </div>
        <div style={{ display: 'flex' }}>
          <AdressText>{adress.text}</AdressText>
        </div>
      </AdressContainer>
      <ContactForm mailerAdress="https://mailer.psychotherapie-norpoth.de/mail.php" />
    </SectionInnerBox>
  </SectionOuterBox>
)

import styled from 'styled-components'

interface SectionProps {
  prevImage?: boolean
  nextImage?: boolean
}

export const SectionOuterBox = styled.div<SectionProps>`
  padding-top: ${(props) => (props.prevImage ? '3rem' : '0')};
  padding-bottom: ${(props) => (props.nextImage ? '4.5rem' : '0')};
  position: relative;
  z-index: 20;
  /* min-height: 100vh; */
  background-color: white;
  transform: translateZ(0);
`

export const SectionInnerBox = styled.div<SectionProps>`
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 768px;
  margin: 0px auto;
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import '../fonts/fonts.css'

export const GlobalStyle = createGlobalStyle`
    ${normalize}
   
    html {
        scroll-behavior: smooth;
    }

    * {
        box-sizing: border-box;
    }

    body {
        overflow-x: hidden;
        background: white;
        color: black;
        font-family: "Libre Franklin", Helvetica Neue, helvetica, arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .grecaptcha-badge {
        z-index: 1000;
        /* display: none !important; */
    }

    h2 {
        color: rgb(34, 34, 34);
        font-size: 0.875rem;
        font-weight: 800;
        letter-spacing: 0.14em;
        text-transform: uppercase;
        margin: 2rem 0px;
    }
`

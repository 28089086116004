import React, { useState } from 'react'
import { ImageDataLike } from 'gatsby-plugin-image'

import { useWindowScroll } from '../../hooks'

import { Hamburger } from './hamburger'
import { NavigationLogo } from './NavigationLogo'

import { NavigationMenu, NavigationBox, NavigationOffScreen, NavigationLink } from './styles'

interface NavigationProps {
  readonly logo: ImageDataLike
  readonly title: string
  readonly menu: Array<{ title: string; path: string }>
}

export const Navigation = ({ logo, title, menu }: NavigationProps) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false)
  const [scroll] = useWindowScroll()

  return (
    <NavigationBox isScroll={scroll >= 0.02} isNavigationOpen={isNavigationOpen}>
      <NavigationMenu isNavigationOpen={isNavigationOpen}>
        {menu.map((linkobj, index) => {
          return (
            <NavigationLink
              key={String(index)}
              onClick={() => setIsNavigationOpen(!isNavigationOpen)}
              to={linkobj.path}
            >
              {linkobj.title}
            </NavigationLink>
          )
        })}
      </NavigationMenu>
      <Hamburger onClickHandler={() => setIsNavigationOpen(!isNavigationOpen)} isNavigationOpen={isNavigationOpen} />
      <NavigationLogo title={title} logo={logo} isScroll={scroll >= 0.02} />
      <NavigationOffScreen onClick={() => setIsNavigationOpen(!isNavigationOpen)} isNavigationOpen={isNavigationOpen} />
    </NavigationBox>
  )
}

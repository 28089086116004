import React from 'react'

import { AlertContainer } from './AlertStyles'

interface AlertProps {
  readonly isVisible: boolean
  readonly isSuccess: boolean
  readonly message: string
}

export const Alert = ({ isVisible, isSuccess, message }: AlertProps) => (
  <AlertContainer isVisible={isVisible} isSuccess={isSuccess}>
    {message}
  </AlertContainer>
)

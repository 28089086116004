import React, { useState } from 'react'

import { Accordion } from './Accordion'
import { AccordionsBox } from './AccordionsStyles'

interface Props {
  content: {
    [key: string]: string
  }
}

export const Accordions = ({ content }: Props) => {
  const [openIndex, setOpenIndex] = useState(-1)

  const onClickHandler = (index: number) => {
    if(openIndex === index){
      setOpenIndex(-1)
    }else{
      setOpenIndex(index)
    }
  }

  return (
    <>
      <h2>Kosten</h2>
      <AccordionsBox>
        {Object.entries(content).map(([key, value], index) => (
          <Accordion 
            key={key} 
            header={key} 
            content={value} 
            isOpen={openIndex === index} 
            onClickHandler={() => onClickHandler(index)}/>
        ))}
      </AccordionsBox>
    </>
  )
}

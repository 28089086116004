import { Link } from 'gatsby'
import styled from 'styled-components'

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  padding: 1rem 4rem;
  z-index: 99;
  box-shadow: 0 0px 2.2px rgba(0, 0, 0, 0.02), 0 0px 5.3px rgba(0, 0, 0, 0.028), 0 0px 10px rgba(0, 0, 0, 0.035),
    0 0px 17.9px rgba(0, 0, 0, 0.042), 0 0px 33.4px rgba(0, 0, 0, 0.05), 0 0px 80px rgba(0, 0, 0, 0.07);
  @media (min-width: 480px) {
    align-items: center;
    flex-direction: row;
  }
`

export const FooterLink = styled(Link)`
  padding-right: 1rem;
  @media (min-width: 480px) {
    padding-right: 0;
    padding-left: 1rem;
  }
`

export const FooterLinkWrapper = styled.div`
  margin: 0.5rem 0;
  @media (min-width: 480px) {
    margin: 0;
  }
`

import React from 'react'
import { Helmet } from 'react-helmet'

import { GlobalStyle, CookieLaw, CookieProvider } from '../components'

import { Footer } from './_footer'

// if (typeof window !== 'undefined') {
//   require('smooth-scroll')('a[href*="/#"]', { offset: () => 130 })
// }

interface DefaultLayoutType {
  children: React.ReactNode
}

export const DefaultLayout = ({ children }: DefaultLayoutType) => (
  <CookieProvider>
    <GlobalStyle />
    <Helmet>
      <meta charSet="utf-8" />
      <title>Maren Norpoth | M. Sc. Psych. | Privatpraxis | Psychotherapie | Monheim am Rhein</title>
      <meta
        name="description"
        content="Maren Norpoth | Verhaltenstherapie | Klärungsorientierte Psychotherapie | Privatpatienten und Selbstzahler | Termine und Sprechzeiten nach telefonischer Vereinbarung, Tel: 017624127350"
      />
    </Helmet>
    <CookieLaw />
    {children}
    <Footer />
  </CookieProvider>
)

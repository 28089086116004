import React from 'react'
import { Link } from 'gatsby'
import { getImage, ImageDataLike } from 'gatsby-plugin-image'

import { NavigationTitle, NavigationLogoBox, NavigationLogo as DefaultLogo } from './styles'

interface LogoProps {
  logo: ImageDataLike
  isScroll: boolean
  title: string
}

export const NavigationLogo = ({ logo, title, isScroll }: LogoProps) => (
  <NavigationLogoBox isScroll={isScroll}>
    <Link to="/">
      {logo ? (
        <DefaultLogo
          image={getImage(logo)!}
          style={{
            backgroundSize: 'contain',
            margin: '0.5rem 0'
          }}
          alt="Logo"
        />
      ) : (
        <NavigationTitle>{title}</NavigationTitle>
      )}
    </Link>
  </NavigationLogoBox>
)

import styled from 'styled-components'


interface ButtonProps {
  readonly isRed?: boolean
  readonly disabled?: boolean
}

export const Button = styled.button<ButtonProps>`
  cursor:  ${({disabled}) => disabled ? 'auto' : 'pointer' };
  transition: transform 0.25s ease-out, background 0.25s ease-out;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({isRed}) => isRed ? '#e74c3c' : '#70ad58' };
  background-color: white;
  color: black;
  padding: 0.4rem 1.25rem;
  font-weight: 600;
  box-shadow: rgb(0 0 0 / 2%) 0px 1.2px 2.2px, rgb(0 0 0 / 3%) 0px 2.8px 5.3px, rgb(0 0 0 / 4%) 0px 5.3px 10px, rgb(0 0 0 / 4%) 0px 9.4px 17.9px, rgb(0 0 0 / 5%) 0px 17.5px 33.4px, rgb(0 0 0 / 7%) 0px 42px 80px;
  opacity:  ${({disabled}) => disabled ? '0.5' : '1' };
  &:hover {
    transform: ${({disabled}) => disabled ? 'none' : 'translateY(-2px)' };
    background-color: ${({isRed, disabled}) =>  disabled ? 'white' : isRed ? '#c0392b' : 'rgba(112, 173, 88, 0.7)' };
  }
  &:focus {
    outline: 0;
  }
`

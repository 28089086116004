import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { Text } from './SectionListStyles'

type TransformedImageType = {
  name: string
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData
  }
}

export const renderText = (
  text: string,
  allTextImages: Array<TransformedImageType>,
  file: {
    imageFile: {
      publicURL: string
    }
  } | null,
) => {
  const textStrings = text.split(/<img([\w\W]+?)>/g)
  return textStrings.map((textPart, index) => {
    if (textPart.startsWith('<')) {
      if (file) {
        const newText = textPart.replace(/\href="File"/g, `href="${file.imageFile.publicURL}"`)
        return <Text key={String(index)} dangerouslySetInnerHTML={{ __html: newText }} />
      }
      return <Text key={String(index)} dangerouslySetInnerHTML={{ __html: textPart }} />
    }
    const image = allTextImages.find((imageObj) => textPart.includes(imageObj.name))
    if (image) {
      return <GatsbyImage key={String(index)} image={image.childImageSharp.gatsbyImageData} alt="Text Image" />
    }
    return null
  })
}

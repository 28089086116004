import React from 'react'
import { getImage, ImageDataLike } from 'gatsby-plugin-image'

import { ArrowDown, ArrowDownSecond, Background, HeroBox } from './HeroStyles'

interface HeroProps {
  readonly backgroundImage: ImageDataLike
  readonly anchor: string
}

export const Hero = ({ backgroundImage, anchor }: HeroProps) => {
  const image = getImage(backgroundImage)

  const scrollTo = (_anchor: string) => {
    const anchorElement = document.getElementById(_anchor)
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <HeroBox>
      <Background image={image!} alt="Hero Image" />
      <ArrowDownSecond onClick={() => scrollTo(anchor)} />
      <ArrowDown onClick={() => scrollTo(anchor)} />
    </HeroBox>
  )
}

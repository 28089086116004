import React from 'react'

import { Footer as DefaultFooter } from '../components'

const footerLinks = [
  { title: 'Impressum', path: '/impressum' },
  { title: 'Datenschutz', path: '/datenschutz' },
]

const Footer = () => (
  <DefaultFooter
    begin={2022}
    name="Maren Norpoth"
    links={footerLinks}
  />
)

export { Footer }

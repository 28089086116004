/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image'

import { convertToBgImage } from 'gbimage-bridge'

import { useWindowScroll } from '../../hooks'

import { Accordions } from '../Accordions'
import { SectionOuterBox, SectionInnerBox } from '../Section'

import { ImageBox, SectionListBox, SectionBox, ImageOuterBox } from './SectionListStyles'
import { renderText } from './renderText'

interface Props {
  readonly accordion: {
    [key: string]: string
  }
  readonly sectionlist: Array<{
    readonly title: string
    readonly text: string
    readonly anchor: string
    readonly File: {
      imageFile: {
        publicURL: string
      }
    } | null
    readonly picture: {
      imageFile: ImageDataLike
    }
    readonly allTextImages: Array<{
      name: string
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }>
  }>
}

export const SectionList = ({ sectionlist, accordion }: Props) => {
  const [scroll] = useWindowScroll()

  const checkImage = (index: number) => {
    if (index <= 0 || index >= sectionlist.length) return true
    if (sectionlist[index].picture === null) return false
    return true
  }

  return (
    <SectionListBox>
      {sectionlist &&
        sectionlist.map(({ title, text, anchor, picture, File, allTextImages }, index) => {
          const prevImage = checkImage(index - 1)
          const nextImage = checkImage(index)

          return (
            <SectionBox key={String(index)}>
              <SectionOuterBox prevImage={prevImage} nextImage={nextImage}>
                {anchor === 'risiken' && (
                  <SectionInnerBox id="kosten">
                    <Accordions content={accordion} />
                  </SectionInnerBox>
                )}
                <SectionInnerBox id={anchor}>
                  <div>
                    <h2>{title}</h2>
                    {renderText(text, allTextImages, File)}
                  </div>
                </SectionInnerBox>
              </SectionOuterBox>
              {picture && (
                <ImageOuterBox>
                  <ImageBox {...convertToBgImage(getImage(picture.imageFile))} alt={title} />{' '}
                </ImageOuterBox>
              )}
            </SectionBox>
          )
        })}
    </SectionListBox>
  )
}

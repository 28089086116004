import React, { useState } from 'react'

import { Button } from '../Button'

import { OffScreen, ModalBox, ModalTitle, ModalText } from './ModalStyles'

interface ModalProps {
  readonly title: string
  readonly text: string
  readonly buttonYes: string
  readonly isModalEnabled: boolean
}

export const Modal = ({ title, text, buttonYes, isModalEnabled }: ModalProps) => {
  const [clicked, setClicked] = useState(false)
  const enabled = !isModalEnabled || clicked

  return (
    <OffScreen isDisabled={enabled}>
      <ModalBox isDisabled={enabled}>
        <ModalTitle>{title}</ModalTitle>
        <ModalText>{text}</ModalText>
        <Button onClick={() => setClicked(true)}>{buttonYes}</Button>
      </ModalBox>
    </OffScreen>
  )
}

import styled from 'styled-components'

interface AlertContainerProps {
  isVisible: boolean
  isSuccess: boolean
}

export const AlertContainer = styled.div<AlertContainerProps>`
  background-color: ${(props) => (props.isSuccess ? '#e55039' : '#b8e994')};
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 4px;
  transition: opacity 0.3s;
`

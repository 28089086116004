import { useEffect, useState } from 'react'

export const useWindowScroll = () => {
  const [scroll, setScroll] = useState(0)

  const handleScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    setScroll(winScroll / height)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll())
    return window.removeEventListener('scroll', () => handleScroll())
  }, [])

  return [scroll]
}

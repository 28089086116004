import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

export const SectionListBox = styled.div`
  margin: 1rem 0;
`
// interface ImageProps {
//   position: boolean
// }

// export const Image = styled(GatsbyImage)<ImageProps>`
//   float: ${(props) => (props.position ? 'right' : 'left')};
//   border: 1px solid black;
//   padding: 1rem;
//   max-width: 100%;
//   margin-top: ${(props) => (props.position === false ? '0.25rem' : '0.35rem')};
//   /* margin-bottom: 0.85rem; */
//   margin-right: ${(props) => (props.position === false ? '1rem' : 0)};
//   @media (min-width: 768px) {
//     margin-right: ${(props) => (props.position === false ? '1.6rem' : 0)};
//     margin-bottom: ${(props) => (props.position === false ? 0 : '0.7rem')};
//     margin-left: ${(props) => (props.position === false ? 0 : '2rem')};
//   }
// `

export const SectionBox = styled.div``

export const Text = styled.div`
  hyphens: auto;
  font-size: 1rem;
  line-height: 1.5;
`
export const ImageOuterBox = styled.div`
  overflow: hidden;
  height: 50vh;
`

export const ImageBox = styled(BackgroundImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  // https://github.com/timhagn/gatsby-background-image/issues/71
  &,
  &::before,
  &::after {
    transform: translateZ(-0.05px) scale(1.05);
    background-attachment: fixed;
  }
  @media screen and (max-width: 1200px) {
    &,
    &::before,
    &::after {
      transform: translateZ(0px) scale(1);
      background-attachment: scroll;
    }
  }
`

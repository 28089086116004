import React from 'react'

import { FooterContainer, FooterLink, FooterLinkWrapper } from './FooterStyles'

interface FooterProps {
  readonly name: string
  readonly begin: number
  readonly links: Array<{ title: string; path: string }>
}

export const Footer = ({ name, begin, links }: FooterProps) => {
  const copyrightYear = new Date().getFullYear() === begin ? `© ${begin.toString()}` : `© ${begin.toString()}-${new Date().getFullYear().toString()}`
  
  return (
  <FooterContainer>
    <div>{`${copyrightYear} ${name} Alle Rechte vorbehalten.`}</div>
    <FooterLinkWrapper>
      {links.map((linkobj, index) => {
        return (
          <FooterLink key={String(index)} to={linkobj.path}>
            {linkobj.title}
          </FooterLink>
        )
      })}
    </FooterLinkWrapper>
  </FooterContainer>
)}

import React, { useState } from 'react'

import { ArrowDown } from './ArrowDown'
import { ArrowRight } from './ArrowRight'

import {
  AccordionBox,
  AccordionHeader,
  AccordionHeaderText,
  AccordionHeaderArrow,
  AccordionContent,
} from './AccordionStyles'

interface Props {
  header: string
  content: string
  isOpen: boolean
  onClickHandler: () => void
}

export const Accordion = ({ header, content, isOpen, onClickHandler }: Props) => (
    <AccordionBox>
      <AccordionHeader onClick={() => onClickHandler()}>
        <AccordionHeaderArrow>{isOpen ? <ArrowDown /> : <ArrowRight />}</AccordionHeaderArrow>
        <AccordionHeaderText>{header.replace('_', ' ')}</AccordionHeaderText>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>{content}</AccordionContent>
    </AccordionBox>
  )
